import request from '@/plugins/axios'

export function authLogin(data) {
  // 获取twitter帐号列表
  return request({
    url: '/authentication/authLogin',
    method: 'post',
    data
  })
}

export function logOut() {
  return request({
    url: '/authentication/logout',
    method: 'post'
  })
}

export function userList(data) {
  return request({
    url: `/authentication/users`,
    method: 'get',
    params: data
  })
}
export function singleUser(data) {
  return request({
    url: `/investigation/countChangeLogs`,
    method: 'get',
    params: data
  })
}
export function investigationCountChange(id, change_value) {
  return request({
    url: `/authentication/users/${id}/investigationCountChange`,
    method: 'PUT',
    data: {
      change_value: change_value
    }
  })
}
