<template>
  <div class="total-all">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '总条数 0'
    }
  }
}
</script>

<style lang="scss" scoped>
.total-all {
  width: 100%;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  background: white;
  font-size: 12px;
  color: rgb(151, 148, 148);
  padding: 0 10px;
}
</style>
