<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  mounted() {
    this.getWindowClient()

    window.addEventListener('resize', () => {
      this.getWindowClient()
    })
  },
  methods: {
    getWindowClient() {
      let windowWidth = document.documentElement.clientWidth //实时屏幕宽度
      let windowHeight = document.documentElement.clientHeight //实时屏幕高度
      this.$store.commit('windowData/SETWIDTH', windowWidth)
      this.$store.commit('windowData/SETHEIGHT', windowHeight)
    }
  }
}
</script>

<style>
/*#app {*/
/*  font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*  margin-top: 60px;*/
/*}*/
html {
  overflow: hidden;
}
body {
  margin: 0;
}
</style>
