<template>
  <div class="no-more-page">
    <li v-if="nomore">加载中...</li>
    <li v-else>没有更多了...</li>
  </div>
</template>

<script>
export default {
  props: {
    nomore: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.no-more-page {
  width: 100%;
  padding-bottom: 5px;
  li {
    width: 100%;
    list-style: none;
    text-align: center;
    color: #747272;
    font-size: 12px;
  }
}
</style>
