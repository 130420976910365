import pagination from './pagination/Pagination.vue'
import noData from './pagination/NoData.vue'
import totalAll from './pagination/TotalAll.vue'
import Nomore from './pagination/Nomore.vue'
const components = {
  install: function(Vue) {
    Vue.component('pagination', pagination)
    Vue.component('noData', noData)
    Vue.component('totalAll', totalAll)
    Vue.component('noMorePage', Nomore)
  }
}
export default components
